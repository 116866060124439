import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Employees = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [rolesList, setRolesList] = useState([]);
  const [gotRoles, setgotRoles] = useState(false);
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    getRoleListFromServer();
    let screenPermissions = RolePermissions.screenPermissions("Employees");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };
  // componentDidMount = () => {
  //   //Screen permisions value can be edit,view, no view
  //   let screenPermissions = RolePermissions.screenPermissions('Test');
  //   if (screenPermissions) {
  //     this.setState({
  //       rolePermission: screenPermissions
  //     })
  //   }
  //   // this.getRoleListFromServer()
  // }
  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };
  const getRoleListFromServer = async () => {
    let filterCriteria = {},
      url;
    filterCriteria.direction = "asc";
    url = `roles?filter=${JSON.stringify(filterCriteria)}`;
    fetchMethodRequest("GET", url)
      .then(async (res) => {
        if (res && res.roles && res.roles.length > 0) {
          let rolesList = [];
          for (let obj of res.roles) {
            if (obj.role) rolesList.push({ value: obj.role, label: obj.role });
          }
          // await this.setState({ rolesList: rolesList,gotRoles:true });
          setRolesList(rolesList);
          setgotRoles(true);
        }
      })
      .catch((err) => {
        setgotRoles(true);
        return err;
      });
  };
  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Name",
        derivedValue: "name=undefined",
        id: "name",
        filter: true,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "hospitalIdSearch",
        type: "text",
        placeholder: "Practice Name",
        label: "Practice Name",
        width: 150,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Practice Name",
        derivedValue: "hospitalIdSearch=undefined",
        id: "hospitalIdSearch",
        filter: true,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "hospitalIdSearch",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      // {
      //   name: "hospitalId",
      //   type: "relateAutoComplete",
      //   placeholder: "Practice Name",
      //   label: "Practice Name",
      //   width: "150px",
      //   addFormOrder: 7,
      //   editFormOrder: 7,
      //   header: "Practice Name",
      //   derivedValue: "hospitalID=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "hospitalId",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: false,
      //   globalSearchField: "false",
      //   controllerId: 1001,
      //   searchField: "hospitalName",
      //   fieldType: "relateAutoComplete",
      //   populteFields: [],
      //   displayFields: [],
      //   controllerName: "Hospital",
      //   searchApi: "hospitals",
      //   isMultiple: false,
      //   textAlign: "Center",
      //   show: true,
      //   field: "hospitalId",
      //   filter: true,
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      // },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: 150,
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Email",
        derivedValue: "email=undefined",
        id: "email",
        filter: true,
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinregisterForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        type: "textarea",
        placeholder: "Address",
        label: "Address",
        id: "address",
        width: 180,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "left",
        show: true,
        disabled: false,
        globalSearchField: "true",
        field: "address",
        header: "Address",
        addFormOrder: 3,
        editFormOrder: 3,
        defField: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "role",
        type: "dropDown",
        placeholder: "Role",
        label: "Role",
        width: 110,
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Role",
        derivedValue: "role=role=undefined",
        actions: [],
        actionsNumber: [],
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        options: [],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "role",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phone",
        type: "text",
        placeholder: "Phone",
        label: "Phone",
        width: 110,
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Phone",
        derivedValue: "phone=undefined",
        id: "phone",
        filter: true,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "phone",
        showOrHideFields: [],
        globalSearchField: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "reportingTo",
        type: "relateAutoComplete",
        placeholder: "ReportingTo",
        label: "ReportingTo",
        width: 110,
        addFormOrder: 6,
        editFormOrder: 6,
        header: "ReportingTo",
        derivedValue: "reportingTo=reportingTo=undefined",
        actions: [],
        actionsNumber: [],
        id: "reportingTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "false",
        controllerId: "employee",
        searchField: "name",
        fieldType: "relateAutoComplete",
        controllerName: "employee",
        searchApi: "employees",
        textAlign: "Center",
        show: true,
        field: "reportingTo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      {
        name: "education",
        type: "text",
        placeholder: "Education",
        label: "Education",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Education",
        derivedValue: "education=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "education",
        filter: true,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "education",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.monthDateFormat,
        textAlign: "Center",
        disabled: false,
        show: true,
        field: "created",
        addFormOrder: 10,
        editFormOrder: 10,
        defField: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.monthDateFormat,
        textAlign: "Center",
        disabled: false,
        show: true,
        field: "updated",
        addFormOrder: 11,
        editFormOrder: 11,
        defField: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "hospitalId",
        type: "relateAutoComplete",
        placeholder: "Practice Name",
        label: "Practice Name",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "hospitalId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1009,
        searchField: "fullHospitalName",
        fieldType: "relateAutoComplete",
        populateValue: "hospitalID",
        populateField: "hospitalName",
        populteFields: [],
        displayFields: ["hospitalID"],
        controllerName: "Hospitals",
        searchApi: "hospitals",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "firstName",
        type: "text",
        placeholder: "First Name",
        label: "First Name",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "firstName=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "firstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        // fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "lastName",
        type: "text",
        placeholder: "Last Name",
        label: "Last Name",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "lastName=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "lastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: 150,
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "email=undefined",
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinregisterForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: true,
      },
      {
        name: "password",
        type: "password",
        placeholder: "Password",
        label: "Password",
        id: "password",
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "false",
        controllerName: null,
        show: true,
        displayInSettings: false,
        disabled: true,
        addFormOrder: 5,
        editFormOrder: 5,
        mobile: true,
        required: true,
        isAddFormHidden: false,
        isEditFormHidden: true,
      },
      {
        name: "address",
        type: "textarea",
        placeholder: "Address",
        label: "Address",
        id: "address",
        width: 180,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        show: true,
        disabled: false,
        globalSearchField: "true",
        addFormOrder: 6,
        editFormOrder: 6,
        defField: true,
        isFieldRequired: "false",
        mobile: true,
        required: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "role",
        type: "dropDown",
        placeholder: "Role",
        label: "Role",
        width: 110,
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "role=role=undefined",
        actions: [],
        actionsNumber: [],
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        options: [],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        // dependent: [{ "Doctor": ["DoctorDesignation", "specialization", "department", "Certificates"] }, { "Operative": ["provider"] }],
      },
      {
        name: "DoctorDesignation",
        type: "text",
        placeholder: "Designation",
        label: "Designation",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "DoctorDesignation=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "DoctorDesignation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "specialization",
        type: "text",
        placeholder: "Specialization",
        label: "Specialization",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "specialization=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "specialization",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: `department`,
        type: "text",
        placeholder: `Department`,
        label: `department`,
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "department=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "department",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "Certificates",
        type: "text",
        placeholder: "Certificates",
        label: "Certificates",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "Certificates=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Certificates",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "education",
        type: "text",
        placeholder: "Education",
        label: "Education",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "education=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "education",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phone",
        type: "number",
        placeholder: "Phone",
        label: "Phone",
        width: 110,
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "phone=undefined",
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        fieldType: 'phone',
        maxLength: config.phoneNumberLength,
        controllerId: null,
        show: true,
        showOrHideFields: [],
        globalSearchField: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "reportingTo",
        type: "relateAutoComplete",
        placeholder: "ReportingTo",
        label: "ReportingTo",
        width: 110,
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "reportingTo=reportingTo=undefined",
        actions: [],
        actionsNumber: [],
        id: "reportingTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "true",
        controllerId: "employee",
        searchField: "name",
        fieldType: "relateAutoComplete",
        controllerName: "employee",
        searchApi: "employees",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Name",
        derivedValue: "name=undefined",
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: 150,
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Email",
        derivedValue: "email=undefined",
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        type: "textarea",
        placeholder: "Address",
        label: "Address",
        id: "address",
        width: 180,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        show: true,
        disabled: false,
        globalSearchField: "true",
        field: "address",
        header: "Address",
        addFormOrder: 3,
        editFormOrder: 3,
        defField: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "role",
        type: "dropDown",
        placeholder: "Role",
        label: "Role",
        width: 110,
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Role",
        derivedValue: "role=role=undefined",
        actions: [],
        actionsNumber: [],
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        options: [],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "role",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phone",
        type: "text",
        placeholder: "Phone",
        label: "Phone",
        width: 110,
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Phone",
        derivedValue: "phone=undefined",
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "phone",
        showOrHideFields: [],
        globalSearchField: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "reportingTo",
        type: "relateAutoComplete",
        placeholder: "ReportingTo",
        label: "ReportingTo",
        width: 110,
        addFormOrder: 6,
        editFormOrder: 6,
        header: "ReportingTo",
        derivedValue: "reportingTo=reportingTo=undefined",
        actions: [],
        actionsNumber: [],
        id: "reportingTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "true",
        controllerId: "employee",
        searchField: "name",
        fieldType: "relateAutoComplete",
        controllerName: "employee",
        searchApi: "employees",
        textAlign: "Center",
        show: true,
        field: "reportingTo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "password",
        type: "password",
        placeholder: "password",
        label: "password",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "password",
        derivedValue: "password=undefined",
        id: "password",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "password",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },

      {
        name: "education",
        type: "text",
        placeholder: "Education",
        label: "Education",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Education",
        derivedValue: "education=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "education",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "education",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };
  // submit1 = item => {
  //   let x = employeesFields
  //   let objemployees = {}

  //   objemployees["_id"] = item[0]["_id"]
  //   for (let x2 of x) {
  //     objemployees[x2] = item[0][x2]
  //   }
  //   let formFields = this.getFormFields()

  //   formFields = formFields.filter(y => x.includes(y.name))

  //   this.setState({
  //     openNewUserModal: true,
  //     item: objemployees,
  //     newFormFields: formFields
  //   });
  // }

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };
  const getChangedRoleOptions = () => {
    let fields = getFormFields();
    if (fields && fields.length > 0) {
      for (let obj of fields) {
        if (obj.name == "role") {
          obj.options = rolesList && rolesList.length > 0 ? rolesList : [];
        }
      }
    }
    return fields;
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.employees}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  const custonActionIcon = (item) => {
    const loginCredentials = localStorage.getItem('loginCredentials');
    const employeeDetails = loginCredentials ? JSON.parse(loginCredentials) : {};  
    return item?._id === employeeDetails._id ? ["edit"] : ["edit", "delete"];
  }

  return (
    <span>
      {gotRoles && (
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getChangedRoleOptions}
          // MobileTableFields={this.getMobileTableFields}
          // getTableFields={this.getTableFields}
          // formFields={this.getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          // globalSearch={'Display Name/Email'}
          // type='Employees'
          // apiUrl={apiCalls.Employees}

          // exportRequried={true}

          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          sampleFilePath={filePath.employees}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          globalSearch={"Name/Email/Address/Role"}
          displayName="Employees"
          type="Employees"
          routeTo={apiCalls.employees}
          sideFormLeftOrRight="right"
          displayViewOfForm="sideForm"

          apiResponseKey={apiCalls.employees?.toLowerCase()}
          apiUrl={apiCalls.employees}
          selectedId={params.id}
          displayActionsInTable={true}
          actionDependentFunction={custonActionIcon}
        />
      )}
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="employees"
          apiUrl={apiCalls.employees}
        />
      ) : null}
    </span>
  );
};

export default Employees;
